import { CustomHTMLAnchorElement } from '../../../node_modules/custom-web-component/index.js';

/**
 * @public @name ConfirmLinkClick
 * @extends CustomHTMLAnchorElement
 * @description Library web component extending the span element with a word count from an input/textarea
 */
class ConfirmLinkClick extends CustomHTMLAnchorElement {

	/**
     * @public @constructor @name constructor
	 * @description Triggered when component is instantiated (but not ready or in DOM, must call super() first)
	 */
    constructor() {
        // Always call super first in constructor
        super();
    }

    /**
     * @public connected()
     * Invoked when node is connected/added to the DOM
     */
    connected() {
        this.addEventListener('click', this._clickEvent = this._confirmClick.bind(this));
    }
	
    /**
     * @public disconnected()
     * Invoked when node is disconnected/removed from the DOM
     */
    disconnected() {
        this.addRemoveListener('click', this._clickEvent);
    }

    /**
	 * @public _confirmClick()
	 * The link got clicked
	 * @param {Event} ev The event that kicked the method
     */
    _confirmClick(ev) {
        const result = confirm(`Are you sure you want to go to '${this.innerText}'? \n\n Link Location: ${this.href}`);
        if (!result) ev.preventDefault();
    }
}

// Define the new element
customElements.define('confirm-link-click', ConfirmLinkClick, { extends: 'a' });