import '../node_modules/reflect-constructor/reflect-constructor.js';
import './HelloWorld/hello-world.mjs';
import './Examples/Components/my-switch.mjs';
import './Examples/Components/my-box-select.mjs';
import './Examples/Components/material-icon-general.mjs';
import './Examples/Applications/my-app.mjs';
import './Examples/Applications/my-single-page-app.mjs';
import './Examples/Extensions/shock-button.mjs';
import './Examples/Extensions/word-count.mjs';
import './Examples/Extensions/confirm-link-click.mjs';

// if ('serviceWorker' in navigator) navigator.serviceWorker.register('/sw.js');

console.log('Powered by Custom Web Component');