import { CustomHTMLButtonElement } from '../../../node_modules/custom-web-component/index.js';

/**
 * @public @name ShockButton
 * @extends CustomHTMLButtonElement
 * @description Library web component extending the button element with a shock effect
 */
class ShockButton extends CustomHTMLButtonElement {

	/**
     * @public @constructor @name constructor
	 * @description Triggered when component is instantiated (but not ready or in DOM, must call super() first)
	 */
	constructor() {
		super();

		this.value;
		this.clickedEvent;
		this.downEvent;
	}

    /**
     * @public connected()
     * Invoked when node is connected/added to the DOM
     */
	connected() {
		this.addEventListener('mousedown', this.downEvent = this._down.bind(this));
		this.addEventListener('touchstart', this.downEvent = this._down.bind(this));
		this.addEventListener('click', this.clickedEvent = this._clicked.bind(this));

		
	}
	
    /**
	 * @public disconnected()
	 * Invoked when node is disconnected/removed from the DOM
     */
	disconnected() {
		this.removeEventListener('mousedown', this.downEvent);
		this.removeEventListener('touchstart', this.downEvent);
		this.removeEventListener('click', this.clickedEvent);
	}

    /**
	 * @public _clicked()
	 * The button got clicked
	 * @param {Event} ev The event that kicked the method
     */
	_down(ev) {
		this.style.transition = 'opacity, transform 80ms ease-in-out';
		setTimeout(() => {
			this.style.opacity = 0.8;
			this.style.transform = 'scale(0.95)';
		}, 1);
	}

    /**
	 * @public _clicked()
	 * The button got clicked
	 * @param {Event} ev The event that kicked the method
     */
	_clicked(ev) {
		this.style.opacity = 1;
		this.style.transform = 'scale(1.1)';
		setTimeout(() => {
			this.style.transform = 'scale(1)';
			setTimeout(() => {
				this.style.removeProperty('transform');
				this.style.removeProperty('transition');
				this.style.removeProperty('opacity');
			}, 80);
		}, 80);
	}
}

// bootstrap the class as a new web component
customElements.define('shock-button', ShockButton, { extends: 'button' });