import { CustomHTMLSpanElement } from '../../../node_modules/custom-web-component/index.js';

/**
 * @public @name WordCount
 * @extends CustomHTMLSpanElement
 * @description Library web component extending the span element with a word count from an input/textarea
 */
class WordCount extends CustomHTMLSpanElement {

	/**
     * @public @constructor @name constructor
	 * @description Triggered when component is instantiated (but not ready or in DOM, must call super() first)
	 */
    constructor() {
        // Always call super first in constructor
        super();

        // count words in element's parent element
        this.countable = this.parentNode.querySelector('input[word-count]') || this.parentNode.querySelector('textarea[word-count]');
    }

    /**
     * @public connected()
     * Invoked when node is connected/added to the DOM
     */
    connected() {
        if (!this.countable) return;
        this.countable.addEventListener('input', this._countableEvent = this._countWords.bind(this));
        this._countWords();
    }
	
    /**
	 * @public disconnected()
	 * Invoked when node is disconnected/removed from the DOM
     */
    disconnected() {
        if (!this.countable) return;
        this.countable.addRemoveListener('input', this._countableEvent);
    }

    /**
	 * @public _countWords()
	 * The button got clicked
	 * @param {Event} ev The event that kicked the method
     */
    _countWords(ev) {
        let count = this.countable.value.split(/\s+/g).filter((e) => e != '').length;
        this.innerText = `Words: ${count}`;
    }
}

// Define the new element
customElements.define('word-count', WordCount, { extends: 'span' });