import { CustomHTMLElement, html } from '../../../../node_modules/custom-web-component/index.js';

/**
 */
class PageNotFound extends CustomHTMLElement {
	// Define a template
	static template() {
		return html`
			<div id="page-not-found">
				<p style="margin: 0; padding: 0;">404 - not found</p>
			</div>
		`;
	}

	connected() {
		console.log('not-found-connected');
	}

	disconnected() {
		console.log('not-found-disconnected');
	}
}

customElements.define('page-not-found', PageNotFound);