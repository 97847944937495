import { CustomHTMLElement, html } from '../../../../node_modules/custom-web-component/index.js';

/**
 */
class PageTestOne extends CustomHTMLElement {
	// Define a template
	static template() {
		return html`
			<div id="page-test-one">
				<h1>Test One</h1>
				<p>Lucas ipsum dolor sit amet wookiee obi-wan moff calrissian ackbar c-3po qui-gonn skywalker solo biggs. Binks obi-wan
				grievous darth sidious. Jinn yavin antilles bespin darth baba c-3po grievous. Hutt ponda wookiee jinn gonk kashyyyk.
				Antilles coruscant tusken raider organa lars wicket. Tusken raider antilles moff tusken raider. Baba wedge antilles
				solo solo skywalker boba obi-wan darth. Ben c-3po maul zabrak lando ventress. Hutt ponda qui-gonn palpatine.
				Mandalorians moff dantooine darth windu gonk ackbar.</p>
				
				<p>Jabba ackbar solo dantooine fisto calamari wicket moff darth. Luke coruscant wedge jango ackbar hutt ben fett. Tatooine
				jango owen ponda baba palpatine. Maul wicket tatooine organa lando. Dooku maul fett ackbar darth jango. Solo yoda boba
				mon. Maul sidious yoda fisto. Ventress antilles ahsoka grievous jade skywalker organa maul qui-gonn. Mon dagobah luke
				kenobi solo yavin secura bothan antilles. Boba hutt grievous mara padmé antilles. Anakin watto kenobi dantooine. Boba
				windu ackbar moff. Darth antilles solo organa darth ben mara maul dooku.</p>
				
				<p>Mandalore kamino ben ewok darth antilles darth. Dagobah mandalorians palpatine mon skywalker binks zabrak. Luke solo
				kamino organa dagobah organa. Mon tatooine yoda moff darth dagobah organa. Moff organa solo lando mon calamari fett
				anakin hutt. Ben tatooine mon han moff twi'lek jade. Yoda biggs boba kamino kit fisto. Calrissian fett ewok sith jinn.
				Ventress chewbacca biggs watto kenobi obi-wan sith secura. Antilles darth darth darth organa. Vader calamari sebulba
				twi'lek darth skywalker kessel.</p>
				
				<p>Windu obi-wan obi-wan skywalker grievous sidious. Biggs yavin anakin kenobi. Anakin ackbar lobot endor organa.
				Chewbacca mara coruscant cade utapau kit lars coruscant moff. Moff naboo cade tusken raider gamorrean jawa mara.
				Kashyyyk antilles leia mara. Ackbar antilles mara grievous coruscant ackbar darth droid. Mustafar vader mon skywalker
				mustafar anakin lobot amidala mara. Solo skywalker r2-d2 mara fett solo moff c-3p0. Coruscant organa binks jar. Wedge
				windu mandalorians watto. Kit han palpatine mara yavin organa calrissian.</p>
				
				<p>Hutt binks hutt darth kamino. Watto c-3p0 ben han dagobah kenobi yavin. Maul jade k-3po binks. Amidala gamorrean hutt
				kamino moff mustafar leia. Yoda yavin grievous moff skywalker antilles. Biggs lobot obi-wan jango ponda obi-wan fisto
				hutt. Kamino luke darth sidious luuke. Padmé sidious leia fett mandalore leia hutt. Kenobi skywalker wicket anakin
				dooku wookiee. Dantooine mara binks amidala antilles windu coruscant. Binks wookiee skywalker twi'lek skywalker vader
				jar cade antilles. Leia dantooine ponda maul mon biggs obi-wan.</p>
			</div>
		`;
	}

	connected() {
		console.log('page-test-connected');
	}

	disconnected() {
		console.log('page-test-disconnected');
	}
}

customElements.define('page-test-one', PageTestOne);